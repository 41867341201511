import settings from './settings'

export const verifyHost = () => {
  if (!!window.location.port && !settings.adminHost.port) {
    throw `appshell-root-ui is hosted on port ${window.location.port} (presumably localhost), but it is configured for hosting at '${settings.adminHost.origin}'. Reconfigure it.`
  }

  if (!window.location.port && !!settings.adminHost.port) {
    throw `appshell-root-ui is configured for hosting on port ${settings.adminHost.port} (presumably localhost), but is being hosted by '${window.location.origin}'. Reconfigure and redeploy it.`
  }
}
