import axios, { AxiosRequestConfig } from 'axios'
import { IGlobal } from '../interfaces/IGlobal'
import { isWobblyOrHigherEnvironment } from './devUtils'
import { localize } from './localizationUtils'
import { IDecodedToken } from '../interfaces/IDecodedToken'
import {
  IInvokeApiOptions,
  ILoadTokenResponseOptions,
  ILocationURL,
  ICustomerInfoRequest,
  ICustomerInfoResponse
} from '../interfaces/IInvokeApiOptions'
import { offset } from './clockSkew'

declare const Global: IGlobal
declare const AppShellNavigationUI: any

let timerRenewToken = undefined

export const axiosErrorHandler = (error: any) => {
  // Not sure if this is the right thing to do with the error,
  // but at least we're not throwing anymore so a status 400 doesn't
  // bring the entire app to it's knees.
  // Note that sometimes error can be undefined like when the status is 401.
  // Taking a very hands off approach and just passing whatever is here downstream to be handled by the call-site.
  if (!error) return undefined
  if (error && error.response) return error.response
  return error
}

export const loadTokenResponse = async (options: ILoadTokenResponseOptions) => {
  const config: AxiosRequestConfig = {
    xsrfCookieName: 'CSRF_TOKEN',
    xsrfHeaderName: 'X-CSRF-TOKEN',
    headers: {
      ['X-AUTH0']: options.auth0ToggleEnabled ? '1' : '0',
      ['X-ASM']: options.asmToggleEnabled ? '1' : '0',
      ['X-CHECK-TOGGLE']: options.checkToggle ? '1' : '0'
    }
  }

  if (options.withAccessToken === true) {
    config.headers['X-NAVEX-Access'] = 'token'
  }

  // Requests to /auth/non-activity-token are functionally identical to /auth/token, however they are not classed as
  // user activity and therefore should not extend the user's session
  const url = options.skipActivityCheck ? '/auth/non-activity-token' : '/auth/token'

  const tokenResponse = await axios.post(url, undefined, config).catch(axiosErrorHandler)

  return tokenResponse
}

export const clearSessionTimer = () => {
  clearTimeout(timerRenewToken)
}

export const displaySessionExpModal = () => {
  const message = localize('SIGNED_OUT_MESSAGE')
  const okText = localize('SIGN_IN')

  if (window.self !== window.top) {
    // If inside an iframe overwrite the whole page with a message
    document.getElementById('root').innerHTML = message

    // Notify the parent
    window.parent.postMessage('sessionExpired', '*')
  } else if (typeof AppShellNavigationUI !== 'undefined') {
    // If AppShellNavigationUI exists, use its modal
    AppShellNavigationUI.Modal({
      message,
      okText,
      hideCloseButton: true,
      onOK: () => {
        Global.User.signOut()
      }
    })
  } else {
    // Else fallback to window.alert
    window.alert(message)
    Global.User.signOut()
  }
}

export const getDecodedToken = async (auth0ToggleEnabled, asmToggleEnabled, withFullTokenResponse) => {
  const config: AxiosRequestConfig = {
    headers: {
      ['X-AUTH0']: auth0ToggleEnabled ? '1' : '0',
      ['X-ASM']: asmToggleEnabled ? '1' : '0'
    }
  }
  const token = await axios.get('/auth/user-info', config).catch(axiosErrorHandler)
  const payload: Promise<IDecodedToken | undefined> = token.data
  return withFullTokenResponse ? token : payload
}

export const startSessionStalenessTimer = async (auth0ToggleEnabled, asmToggleEnabled) => {
  // We don't need the full token response here, just the payload containing the decoded token
  const decodedToken = await getDecodedToken(auth0ToggleEnabled, asmToggleEnabled, false)
  if (!decodedToken) {
    displaySessionExpModal()
    return
  }

  const { tokenInfo } = decodedToken

  // Determine the delay
  const utcNowInSeconds = Math.ceil(Date.now() / 1000)
  // To calculate clockskew, the offset compares the server clock against the user's clock.
  // We cannot use the server clock in lower environments, so we use the times in the token with a 15 second buffer.
  let delayInSeconds = isWobblyOrHigherEnvironment()
    ? tokenInfo.exp - utcNowInSeconds + offset
    : tokenInfo.exp - tokenInfo.iat - 15
  if (delayInSeconds < 1) {
    delayInSeconds = 1
  }
  Global.Log.info(`ID token will become stale in ${delayInSeconds} seconds`)

  // Set a timeout to call this method again exactly when the token expires
  timerRenewToken = setTimeout(async () => {
    if (asmToggleEnabled) {
      await startSessionStalenessTimer(auth0ToggleEnabled, asmToggleEnabled)
    } else {
      let tokenResponse
      try {
        Global.Log.info(`ID token is now stale, invoking api to refresh`)
        tokenResponse = await loadTokenResponse({
          withAccessToken: false,
          auth0ToggleEnabled,
          asmToggleEnabled: false,
          checkToggle: false
        })
      } catch (err) {
        let warnMessage = `ID token api invocation threw an error`
        if (err.response && err.response.data) {
          warnMessage += `: ${JSON.stringify(err.response.data, null, 2)}`
        }
        Global.Log.warn(warnMessage)
      }

      Global.Log.info(`ID token response status is ${tokenResponse.data.status}`)
      if (tokenResponse.status === 200) {
        await startSessionStalenessTimer(auth0ToggleEnabled, asmToggleEnabled)
      } else {
        displaySessionExpModal()
        return
      }
    }
  }, delayInSeconds * 1000)
}

export const validateApiInvocation = (options: IInvokeApiOptions, windowLocation: ILocationURL) => {
  // prevent callsites from using this function to obtain an access token
  const isUrlAuthToken =
    typeof options.url === 'string' &&
    (options.url.toLowerCase().startsWith('/auth/token') || options.url.toLowerCase().startsWith('auth/token'))
  const isBaseURLEmpty = options.baseURL === null || options.baseURL === undefined || options.baseURL === ''
  const appshellHost = `//${windowLocation.host}`
  const isBaseURLAppshell =
    typeof options.baseURL === 'string' &&
    (options.baseURL.toLowerCase().startsWith(`${windowLocation.protocol}${appshellHost}`) ||
      options.baseURL.toLowerCase().startsWith(appshellHost))
  const isBaseURLAuthToken =
    typeof options.baseURL === 'string' &&
    (options.baseURL.toLowerCase().startsWith(`${windowLocation.protocol}${appshellHost}/auth/token`) ||
      options.baseURL.toLowerCase().startsWith(`${appshellHost}/auth/token`))
  const isForbidden = (isUrlAuthToken && (isBaseURLEmpty || isBaseURLAppshell)) || isBaseURLAuthToken
  if (isForbidden) {
    const errorMessage = `Using Global.API.invokeApi to invoke appshell /auth/token is forbidden`
    throw new Error(errorMessage)
  }
}

export const getCustomerInfo = async (requestData: ICustomerInfoRequest, checkToggle: boolean) => {
  const customerInfo: ICustomerInfoResponse = {
    clientKey: undefined,
    alias: undefined,
    tenantId: undefined
  }

  if (!requestData.alias && !requestData.clientKey) return customerInfo

  const url = requestData.alias ? '/v3/tenant.identity' : '/private/v2/client.info'

  const options: IInvokeApiOptions = {
    authRequired: true,
    checkToggle,
    method: 'POST',
    apiName: 'customermanager-api',
    url,
    data: { ...requestData }
  }

  const response = await Global.API.invokeApi(options)

  if (response && response.data && response.data.data) {
    customerInfo.clientKey = response.data.data.clientKey
    customerInfo.alias = response.data.data.alias || response.data.data.primaryTenantAlias
    customerInfo.tenantId = response.data.data.tenantId
  }
  return customerInfo
}
