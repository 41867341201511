const { parse } = require('cookie')

const extractCookiesFromHeaders = (headers) => {

  if (!headers["cookie"]) {
    return {}
  }

  const cookies = headers['cookie'].reduce((reduced, header) =>
    Object.assign(reduced, parse(header.value)), {})

  return cookies
}

exports.parseAndExtractCookies = (flow) => {
  const { request: { headers }, settings: { cookies: cookieSettings } } = flow
  const cookies = extractCookiesFromHeaders(headers)
  if (!cookies) {
    return {}
  }

  return {
    nonce: cookies[cookieSettings.nonce.name],
    nonceHmac: cookies[cookieSettings.nonceHmac.name],
    pkce: cookies[cookieSettings.pkce.name],
    idToken: cookies[cookieSettings.idToken.name],
    sessionId: cookies[cookieSettings.sessionId.name],
    csrfToken: cookies[cookieSettings.csrfToken.name],
  }
}

exports.expireCookie = (cookie) => {
  const cookieParts = cookie
    .split(';')
    .map((part) => part.trim())
    .filter((part) => !part.toLowerCase().startsWith('max-age'))
    .filter((part) => !part.toLowerCase().startsWith('expires'))
  const expires = `Expires=${new Date(0).toUTCString()}`
  const [, ...settings] = cookieParts; // first part is the cookie value, which we'll clear
  return ['', ...settings, expires].join('; ')
}
