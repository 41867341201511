import { IGlobal } from '../interfaces/IGlobal'
import { loadTokenResponse } from '../utils/apiUtils'
import { isDevelopmentSite } from '../utils/devUtils'

declare const Global: IGlobal

export class GlobalDevTools {
  isDevelopmentSite() {
    return isDevelopmentSite() === true
  }

  isBypassingDevelopmentSiteAuth() {
    const bypassAuthn = Global.Utils.getQueryStringParameter(window.location.href, 'bypassAuthn')
    return this.isDevelopmentSite() && typeof bypassAuthn === 'string' && bypassAuthn.toLowerCase() === 'true'
  }

  async getBearerToken() {
    const auth0ToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('AUTH0_CUTOVER')
    const asmToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('Auth0_ASM_Cutover')

    const tokenResponse = await loadTokenResponse({
      withAccessToken: true,
      auth0ToggleEnabled,
      asmToggleEnabled,
      checkToggle: false
    })
    return tokenResponse.data.data.accessToken
  }
}
