import { isDevelopmentSite } from "./utils/devUtils"
import { IGlobal } from "./interfaces/IGlobal"

declare const pendo: any
declare const Global: IGlobal

export const getAccountId = () => Global.User.getTenantId()

export const registerPendo = () => {
  const isDevSite = isDevelopmentSite()
  const isWipFlagEnabled = Global.Utils.wipFlagExists('WipFlag_Pendo')

  // Pendo should not be enabled for lower environments unless the wip flag is enabled
  const allowPendo = !isDevSite || isWipFlagEnabled

  if (allowPendo) {

    const pendoVisitorId = getPendoVisitorId(isDevSite)
    const pendoKey = getPendoKey(isDevSite)

    if (isWipFlagEnabled && (!pendoVisitorId || !pendoKey)) {
      showDevModeAlert()
    }

    if (pendoVisitorId && pendoKey) {
      initializePendo(pendoVisitorId, pendoKey)
    }
  }
}

const getPendoVisitorId = (isDevSite: boolean) => {
  let puid = undefined

  // Allow a dev-mode-only override to make it easy when developing
  if (isDevSite) {
    puid = getValueFromQueryStringOrLocalStorage("puid")
  }

  // If there wasn't a dev-mode-only override in the querystring or localstorage, then get it from the real location
  if (!puid) {
    puid = Global.User.getPuid()
  }

  return puid
}

const getPendoKey = (isDevSite: boolean) => {
  let pendoKey = undefined

  // Allow a dev-mode-only override to make it easy when developing
  if (isDevSite) {
    pendoKey = getValueFromQueryStringOrLocalStorage("pendoKey")
  }

  // If there wasn't a dev-mode-only override in the querystring or localstorage, then get it from the real location
  if (!pendoKey) {
    pendoKey = Global.API.manifestResponse.ui.pendoKey
  }

  return pendoKey
}

export const getValueFromQueryStringOrLocalStorage = (name) => {
  // First look in the querystring
  let value = Global.Utils.getQueryStringParameter(window.location.href, name)
  if (value) {
    console.warn(`WARNING: You are using a '${name}' from the querystring`)
  }

  // Then look in localstorage
  if (!value) {
    value = window.localStorage.getItem(name)
  }
  if (value) {
    console.warn(`WARNING: You are using a '${name}' from localstorage`)
  }

  return value || undefined
}

export const showDevModeAlert = () => {
  alert(
    'When WipFlag_Pendo is enabled, you must also do any one of the following:\n\n' +
    '- Enable WipFlag_PlatformSession and specify a pendoKey in the appropriate appshell manifest file\n' +
    '- or specify a "puid" and a "pendoKey" in the querystring or localstorage\n\n' +
    'This is only temporary while in development until platform auth and pendo integration are finalized'
  )
}

export const initializePendo = (visitorId: string, pendoKey: string) => {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad']; for (w = 0, x = v.length; w < x; ++w) (function (m) {
        o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
      })(v[w]);
      y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
      z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');

    // console.log('pendo visitorId', visitorId)
    // console.log('pendo key', pendoKey)

    // Call this whenever information about your visitors becomes available
    // Please use Strings, Numbers, or Bools for value types.
    pendo.initialize({
      visitor: {
        id: visitorId,  // Required if user is logged in
        // clientKey: getClientKey(), // Doorman had clientKey here, but Pendo doesn't say to add it
        // email:        // Optional
        // role: 'Unknown'   // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
      isError: false,
      account: {
        id: getAccountId() // Highly recommended
        // name:         // Optional
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    })
  })(pendoKey);
}
