import { IUserProfile } from "../interfaces/IUserProfile"

export const buildUserProfileFromDecodedToken = (decodedToken) => {
  const { userInfo } = decodedToken

  const userProfile: IUserProfile = {
    username: userInfo.username,
    firstName: userInfo.given_name,
    lastName: userInfo.family_name,
    email: userInfo.email,
    emailVerified: userInfo.email_verified,
    attributes: {
      clientkey: [userInfo.clientkey],
      tenantid: [userInfo.tenantid],
      locale: [userInfo.locale],
      middle_name: [userInfo.middle_name],
      puid: [userInfo.puid],
      user_store_role: [userInfo.user_store_role]
    }
  }

  return userProfile
}
