import settings from '../core/settings'
import urljoin from 'url-join'
import { IGlobal } from '../interfaces/IGlobal';
import { appShellRootUIName } from './constants';

declare const Global: IGlobal

export const isDevelopmentSite = () => {
  return settings.IsDevelopmentSite === true
}

export const getInitialLogLevel = () => {
  return settings.InitialLogLevel
}

export const addDevUtils = () => {
  addAltClickListener()
  addCtrlShiftRightClickListener()
}

export const isWobblyOrHigherEnvironment = () => {
  const landingPageUrl = Global.Utils.getLandingPageHost()
  return settings.IsDevelopmentSite === false || landingPageUrl.includes('navex-int')
}

export const computeOverridesPath = (url) => {
  const hostnameSubPath = url.split('//')[1].split('/').length > 1 ? url.split('//')[1].split('/')[1] : ''
      if(hostnameSubPath === 'home' || hostnameSubPath === 'admin'){
        return `/${hostnameSubPath}/overrides`
      }else{
        return '/overrides'
  }
}

const addAltClickListener = () => {
  // If the user performs ALT+CLICK, redirect to /overrides
  document.body.addEventListener('click', (e: MouseEvent) => {
    if (e.altKey) {
      /**
       * Passing false to intentionally use the root-ui loading spinner instead of
       * the contentLoadingHandler's spinner since this transition is unloading the
       * the entire appshell UI not just a micro ui
       */
        Global.UI.showLoadingPanel(appShellRootUIName, false)
        window.location.href = computeOverridesPath(window.location.href)
      }
  })
}

const addCtrlShiftRightClickListener = () => {
  // If the user performs CTRL+SHIFT+RIGHTCLICK, show the DOM breadcrumb
  document.body.addEventListener('contextmenu', (e: MouseEvent) => {
    if (e.shiftKey && (e.ctrlKey || e.metaKey)) {
      e.preventDefault()
      alert(`ID: ${(<HTMLElement>e.target).id}`)
    }
  })
}
