const { expireCookie } = require('./cookies')

exports.buildRedirect307Response = (headers) => {
  const response = {
    status: 307,
    statusDescription: 'Temporary Redirect',
    headers
  }
  return response
}

exports.buildRedirect303Response = (headers) => {
  const response = {
    status: 303,
    statusDescription: 'See Other',
    headers
  }
  return response
}

exports.buildRedirect302Response = (headers) => {
  const response = {
    status: 302,
    statusDescription: 'Found',
    headers
  }
  return response
}

exports.build200Response = (headers) => {
  const response = {
    status: 200,
    headers
  }
  return response
}

exports.buildLocationHeader = (location) => {
  const locationHeader = {
    location: [
      {
        key: 'Location',
        value: location
      }
    ]
  }
  return locationHeader
}

exports.buildContentTypeJsonHeader = () => {
  const header = {
    'content-type': [
      {
        key: 'Content-Type',
        value: 'application/json; charset=utf-8'
      }
    ]
  }
  return header
}

exports.buildSetCookieHeader = (settings, cookies) => {
  const cookieKeys = Object.keys(cookies)
  const cookieHeaders = []
  for (const cookieKey of cookieKeys) {
    const cookieValue = cookies[cookieKey]
    const cookieSettings = settings.cookies[cookieKey]
    const cookieHeader = buildSetCookieHeaderPart(cookieSettings, cookieValue)
    cookieHeaders.push(cookieHeader)
  }
  const cookieHeader = {
    'set-cookie': cookieHeaders
  }
  return cookieHeader
}

const buildSetCookieHeaderPart = (cookieSettings, cookieValue) => {
  let cookieBuilder = `${cookieSettings.name}=`
  if (cookieValue === 'expire') {
    cookieBuilder += expireCookie(`; ${cookieSettings.params}`)
  } else {
    cookieBuilder += `${encodeURIComponent(cookieValue)}; ${cookieSettings.params}`
  }
  const cookieHeader = {
    key: 'Set-Cookie',
    value: cookieBuilder
  }
  return cookieHeader
}

exports.ensureValidRedirectPath = (path) => {
  if (typeof path !== 'string') return '/'
  return path.startsWith('/') ? path : `/${path}`
}
