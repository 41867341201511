import { IMenuItem } from "../interfaces/IMenuItem";

export interface IGroupedMenuItem extends Omit<IMenuItem, "items"> {
  routes: Array<{ path: string, exact: boolean }>
}
export const groupMenuItemsByAppName = (menuItems: IMenuItem[]): IGroupedMenuItem[] => {
  const results: IGroupedMenuItem[] = []
  menuItems.forEach(menuItem => {
    let resultItem = results.filter(x => x.appName === menuItem.appName)[0]
    if (!resultItem) {
      resultItem = { ...menuItem, routes: [] }
      results.push(resultItem)
    }
    resultItem.routes.push({ path: menuItem.path, exact: menuItem.exact === true })
  })
  return results
}
