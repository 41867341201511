import { isDevelopmentSite } from './utils/devUtils'
import { IGlobal } from './interfaces/IGlobal'

declare const iph: any
declare const Global: IGlobal

export const registerZoomin = async () => {
  const isDevSite = isDevelopmentSite()
  const isZoominEnabled: boolean = await Global.LaunchDarkly.assumed.booleanVariation('ZoominIPH', false)

  // Zoomin should not be enabled unless ZoominIPH is enabled
  const allowZoomin = !isDevSite && isZoominEnabled

  if (allowZoomin) {
    initializeZoomin()
  }
}

export const initializeZoomin = () => {
  (function () {
    (function (w, d, s, o, f, js, fjs) {
      w['InProductHelp'] = o
      w[o] =
        w[o] ||
        function () {
          ;(w[o].q = w[o].q || []).push(arguments)
        }
      ;(js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0])
      js.id = o
      js.src = f
      js.async = 1
      fjs.parentNode.insertBefore(js, fjs)
    })(window, document, 'script', 'iph', '//iph.zoominsoftware.io/widget.js')
    iph('init', { id: 6, host: 'navex-be-prod.zoominsoftware.io' })
  })()
}
