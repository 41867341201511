import { GlobalUi } from "../globals/globalUi"
import { GlobalUtils } from '../globals/globalUtils';
import { appShellRootUIName } from "./constants";

export const showCriticalError = (message: string) => {
  // Depnding on when this method is called, Global.UI and Global.Utils might
  // not have been instantiated yet, so use own instances here
  const Global_UI = new GlobalUi()
  const Global_Utils = new GlobalUtils()

  Global_UI.hideLoadingPanel(appShellRootUIName)
  Global_Utils.getRootContainer().innerHTML =
    `<div class="critical-error">${message}</div>`
}
