interface ILogLevels {
  none: number
  error: number
  warn: number
  info: number
  debug: number
  trace: number
}

export interface ILog {
  Levels: ILogLevels
  setLevel: (logLevel: number) => void
  getLevel: () => number
  error: (args: any) => void
  warn: (args: any) => void
  info: (args: any) => void
  trace: (args: any) => void
  debug: (args: any) => void
}

export class GlobalLogger implements ILog {

  private level: number

  Levels: ILogLevels = {
    none: 0,
    error: 10,
    warn: 20,
    info: 30,
    debug: 40,
    trace: 40,
  }

  constructor() {
    this.setLevel(0)
  }

  setLevel(logLevel: number) {
    this.level = logLevel
  }

  getLevel() {
    return this.level
  }

  error(...args) {
    if (this.level >= this.Levels.error) {
      console.error(...args)
    }
  }

  warn(...args) {
    if (this.level >= this.Levels.warn) {
      console.warn(...args)
    }
  }

  info(...args) {
    if (this.level >= this.Levels.info) {
      console.log(...args)
    }
  }

  trace(...args) {
    if (this.level >= this.Levels.trace) {
      console.log(...args)
    }
  }

  debug(...args) {
    return this.trace(...args)
  }

}

export class NoOpLogger implements ILog {

  private level: number

  Levels: ILogLevels = {
    none: 0,
    error: 10,
    warn: 20,
    info: 30,
    debug: 40,
    trace: 40,
  }

  constructor() {
  }

  setLevel(logLevel: number) {
    // no op
  }

  getLevel() {
    return 0
    // no op
  }

  error(...args) {
    // no op
  }

  warn(...args) {
    // no op
  }

  info(...args) {
    // no op
  }

  trace(...args) {
    // no op
  }

  debug(...args) {
    // no op
  }

}
