import { IGlobal } from '../interfaces/IGlobal';
import { IWindow } from '../interfaces/IWindow';

declare const window: IWindow
declare const Global: IGlobal

const callWithBooleanToggle = async (callBack: (toggleValue: boolean) => void, toggleName: string, defaultValue = false) => {
  const toggleValue = await window.Global.LaunchDarkly.assumed.booleanVariation(toggleName, defaultValue)
  callBack(toggleValue)
}

let timeout = undefined
let contentLoadingHandler: (isLoading: boolean) => void;
let contentLoadingHandlersLastCallerId = 'app'
export class GlobalUi {
  showLoadingPanel(callerId = 'app', passToContentLoadingHandler = true) {
    callWithBooleanToggle(contentLoaderEnabled => {
      if (contentLoaderEnabled && typeof contentLoadingHandler === 'function' && passToContentLoadingHandler) {
        contentLoadingHandlersLastCallerId = callerId
        contentLoadingHandler(true)
      } else {
        clearTimeout(timeout)
        const loadingPanel = document.getElementById('loadingpanel')
        if (!!loadingPanel) loadingPanel.style.display = "block"
      }
    }, 'cia-appshell-content-loader')
  }

  hideLoadingPanel(callerId = 'app', passToContentLoadingHandler = true) {
    callWithBooleanToggle(contentLoaderEnabled => {
      if (contentLoaderEnabled && typeof contentLoadingHandler === 'function' && passToContentLoadingHandler && callerId === contentLoadingHandlersLastCallerId) {
        contentLoadingHandler(false)
      } else {
        const loadingPanel = document.getElementById('loadingpanel')
        if (!!loadingPanel) loadingPanel.style.display = "none"
      }
    }, 'cia-appshell-content-loader')
  }

  clearLoadingStates() {
    contentLoadingHandlersLastCallerId = 'app'
    if (typeof contentLoadingHandler === 'function') contentLoadingHandler(false)

    const loadingPanel = document.getElementById('loadingpanel')
    if (!!loadingPanel) loadingPanel.style.display = "none"
  }

  registerContentLoadingHandler = (handler: (isLoading: boolean) => void) => {
    contentLoadingHandler = handler
  }

  hideLoadingPanelWithDelay(callerId = 'app', passToContentLoadingHandler = true) {
    /*
      In singleSpaUtils.scriptTagApp() it hides the loading panel
      after the microfrontend script file has loaded. However,
      more often than not, the microfrontend that was just loaded
      is going to immediately show the loading panel again
      while the microfrontend loads some data via ajax. The process of hiding
      and showing the loading panel like that led to a flicker.

      The timeout below allows it to not flicker during that process of
      hiding and showing the loading panel because as soon
      as showLoadingPanel() gets called the timeout below gets cleared
      out, making it so that hideLoadingPanel() won't get called. In the
      less-likely case that the microfrontend doesn't load any data and thus
      doesn't need to call showLoadingPanel(), then the loading panel will
      get hidden after the timeout below.


      The delay milliseconds should give each microfrontend enough time to
      bootstrap its spa and give that microfrontend a chance to call
      showLoadingPanel(), For reference, between the time that this function
      gets called and landingpage-user-ui calls showLoadingPanel() from within
      its loadModel() method, anywhere between 40 to 60 milliseconds will
      have passed by.
    */
    const delay = 250
    timeout = setTimeout(this.hideLoadingPanel.bind(this, callerId, passToContentLoadingHandler), delay)
  }
}
