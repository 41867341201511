import * as LDClient from 'launchdarkly-js-client-sdk'
import { flatPromise } from '../utils/flatPromise'
import { IGlobal } from '../interfaces/IGlobal'
import { IGlobalLaunchDarkly, LDUserContext } from '../interfaces/IGlobalLaunchDarkly'

declare const Global: IGlobal

interface LDClientInstance {
  instance: LDClient.LDClient
  ready: boolean
  status: 'INITIALIZING' | 'INITIALIZED' | 'FAILED_TO_INITIALIZE'
  /**
   * If not ready, and INITIALIZING, await this promise, then try again.
   */
  getReadyPromise: () => Promise<unknown>
  __readyPromiseQueue: {
    resolve: (value?: unknown) => void
    reject: (value?: unknown) => void
  }[]
}

const getLaunchDarklyInstanceId = (LDClientID: string, UserKey: string) => `${LDClientID}-${UserKey}`
// When we can drop IE we can switch to this version of the regex and use named groups
// const projectFlagLookupRegex = /^\$(?<projectName>\S+):(?<flagName>\S+)$/
const projectFlagLookupRegex = /^\$(\S+):(\S+)$/
// const flagNameLookupRegex = /^\${ *(?<flagName>\S+) *}$/
const flagNameLookupRegex = /^\${ *(\S+) *}$/
const getCallTraceId = (projectName, flagName) => `${projectName}:${flagName}`
export const localStorageId = 'LD_Overrides'
export const defaultTimeToLive = 86400000 //24 hours

export interface LDClientMock {
  initialize: (
    LDClientID: string,
    LDContextObj: LDUserContext,
    options?: LDClient.LDOptions
  ) => {
    on: (event: string, callback: (...args: any[]) => void, context?: any) => void
    variationDetail: (featureFlag: string, defaultVariation: any) => LDClient.LDEvaluationDetail
  }
}

export interface LocalStorageMock {
  setItem: (itemKey: string, itemValue: string) => void
  removeItem: (itemKey: string) => void
  getItem: (itemKey: string) => string | null
}

export interface EvaluationDetail extends LDClient.LDEvaluationDetail {
  callTrace: string[]
}

export interface GlobalLaunchDarklyOptions {
  clientIds?: { project: string; clientId: string }[]
  defaultProject?: string
  getContextObject?: () => LDUserContext
  /** Whether or not to send analytic events */
  sendEvents: boolean
  allowBootstrapping?: boolean
  /** Limit the number of times a flag lookup is allowed.
   * E.g.
   *
   * flagA -> flagB (1) //Default
   *
   * flagA -> flagB -> flagC (2) //You should have a very good reason to need this.
   * */
  lookupLimit?: number
  /** Optional mock of window.localStorage for testing */
  __localStorageMock?: LocalStorageMock
  /** Optional mock of the LD Client SDK for testing */
  __launchDarklyClientSdkMock?: LDClientMock
}

export class GlobalLaunchDarkly implements IGlobalLaunchDarkly {
  /**
   * **Launch Darkly CLient SDK**
   *
   * Access the raw Launch Darkly Client SDK for complete control.
   *
   * Example usage:
   * ```js
   * const ldUserObj = get a user object from somewhere
   * const ldClientID = get a launch darkly's project's client side ID
   * const ldClient = Global.LaunchDarkly.LDClient.initialize(ldClientID, ldUserObj)
   *
   * ldClient.on("ready", () => {
   *  console.log("Feature Flag variation:", ldClient.variation("YOUR_FEATURE_KEY", false))
   * })
   * ```
   *
   * For more information, see the [full documentation here](https://docs.launchdarkly.com/docs/js-sdk-reference).
   */
  public readonly LDClient: any
  public ContextKey: string
  /** A dictionary of Launch Darkly project names and an environment Client-side ID */
  public readonly LDClientIds: { [key: string]: string } = {}
  public readonly defaultProject: string

  public getContextObject: () => LDUserContext

  public sendEvents: boolean = true

  /** A dictionary of Launch Darkly Clients */
  private LDClientInstances: { [key: string]: LDClientInstance } = {}
  /** For use by tests to bypass Global.Utils.getLaunchDarklyUserObj() */
  private allowBootstrapping: boolean = false
  private lookupLimit: number = 1
  private storage: LocalStorageMock

  constructor(options: GlobalLaunchDarklyOptions) {
    //#region Check for errors
    const invalidOptionsErrorHeader = 'GlobalLaunchDarkly:Constructor:InvalidOptions\n'
    if (!options.clientIds || !options.clientIds.length) {
      throw new Error(
        `${invalidOptionsErrorHeader}options.clientIds must contain at least one entry [{project, clientId}]`
      )
    }
    if (!options.defaultProject || !options.clientIds.find((x) => x.project === options.defaultProject)) {
      throw new Error(
        `${invalidOptionsErrorHeader}options.defaultProject was either undefined or did not match an entry in options.clientIds`
      )
    }
    if (!options.getContextObject || typeof options.getContextObject !== 'function') {
      throw new Error(`${invalidOptionsErrorHeader}options.getContextObject was either undefined or was not a function`)
    }
    //#endregion

    //#region Assign from options
    for (const clientData of options.clientIds) {
      this.LDClientIds[clientData.project] = clientData.clientId
    }

    this.defaultProject = options.defaultProject

    this.getContextObject = options.getContextObject

    this.sendEvents = options.sendEvents ?? true

    this.allowBootstrapping = options.allowBootstrapping || false

    this.lookupLimit = options.lookupLimit || 1

    this.LDClient = options.__launchDarklyClientSdkMock || LDClient

    this.storage = options.__localStorageMock

    if (!this.storage) {
      try {
        this.storage = window.localStorage
      } catch (ex) {
        // Can't access window.localStorage when loading MyProfile in
        // an iFrame with a browser that has third-party cookies disabled
        console.warn(ex.message)
      }
    }
    //#endregion
  }

  private initializeLDClient = async (
    LDClientID: string,
    LDContextObj: LDClient.LDSingleKindContext,
    options?: LDClient.LDOptions
  ) => {
    let storedFlagSet = this.getLDFlagSet()
    if (LDClientID !== this.LDClientIds[this.defaultProject]) {
      storedFlagSet = undefined
    }

    // #region Some notes about the options object
    /**
     * This options object is empty but left here as a reminder that we have it.
     *
     * [Read more here](https://docs.launchdarkly.com/docs/js-sdk-reference#section-customizing-your-client)
     *
     * One use case would be to load defaults from localStorage for faster startup time and then update localStorage's
     * cache later. This is potentially dangerous though if multiple users access the site from the same machine, however
     * ideal for when the user is anonymous.
     * [Read More here](https://docs.launchdarkly.com/docs/js-sdk-reference#section-bootstrapping)
     *
     * Another use case would be to enable **Secure Mode**.
     * [Read More here](https://docs.launchdarkly.com/docs/js-sdk-reference#section-secure-mode)
     */
    // #endregion

    const eventOptions: LDClient.LDOptions = this.sendEvents
      ? // Send events only for variation by default
      { sendEventsOnlyForVariation: true }
      : { sendEvents: false }

    const _options: LDClient.LDOptions = {
      evaluationReasons: true,
      bootstrap: storedFlagSet || undefined,
      ...eventOptions,
      ...options
    }

    if (!this.allowBootstrapping) {
      // Don't allow bootstrapping to happen unless it's allowed
      delete _options['bootstrap']
      this.removeLDFlagSet()
    }

    await new Promise<void>((resolve, reject) => {
      /* NEVER REJECT - it causes bugs with the awaits */
      const instanceId = getLaunchDarklyInstanceId(LDClientID, LDContextObj.key)
      this.LDClientInstances[instanceId] = {
        instance: this.LDClient.initialize(LDClientID, LDContextObj, _options),
        ready: false,
        status: 'INITIALIZING',
        getReadyPromise: () => {
          if (this.LDClientInstances[instanceId].status === 'INITIALIZED') return Promise.resolve()

          const { promise, resolve, reject } = flatPromise()
          this.LDClientInstances[instanceId].__readyPromiseQueue.push({ resolve, reject })

          return promise
        },
        __readyPromiseQueue: []
      }
      this.LDClientInstances[instanceId].instance.on('initialized', () => {
        this.LDClientInstances[instanceId].status = 'INITIALIZED'
      })
      this.LDClientInstances[instanceId].instance.on('failed', () => {
        this.LDClientInstances[instanceId].status = 'FAILED_TO_INITIALIZE'
      })
      this.LDClientInstances[instanceId].instance.on('ready', () => {
        this.LDClientInstances[instanceId].ready = true
        resolve()
        while (this.LDClientInstances[instanceId].__readyPromiseQueue.length) {
          const { resolve } = this.LDClientInstances[instanceId].__readyPromiseQueue.shift()
          resolve()
        }
      })
    })
  }

  /**
   * Method to register your own project's LDClientID so that you can use these methods and benefit from toggle grouping.
   * The `projectKey` should match what is used in tri-state toggles.
   *
   * E.g. if your tri-state toggle is `$foo:my-feature` then your `projectKey` would be `foo`
   * @param projectKey A unique identifier for retrieving this LDClientID later e.g. 'platform' etc...
   * @param LDClientId The LDClientId for your project's environment - get this from Launch Darkly's Account Settings
   */
  public registerLDClientId = (projectKey: string, LDClientId: string) => {
    this.LDClientIds[projectKey] = LDClientId
  }

  /**
   * Returns the LD Client Instance for a given project
   * Use either 'platform' or whatever project name you used to register a custom LDClientId
   * You can use the returned instance to do things like get all flags
   * @param project Optional - Defaults to this.defaultProject
   */
  public getClientInstance = async (project: string = this.defaultProject): Promise<LDClient.LDClient | undefined> => {
    const contextObj = this.getContextObject()
    const LDClientId = this.LDClientIds[project]
    if (!LDClientId) return undefined

    const instanceId = getLaunchDarklyInstanceId(LDClientId, contextObj.key)

    if (this.LDClientInstances[instanceId] === undefined) {
      await this.initializeLDClient(LDClientId, contextObj)
      this.ContextKey = contextObj.key
    } else if (this.LDClientInstances[instanceId].status === 'INITIALIZING') {
      await this.LDClientInstances[instanceId].getReadyPromise()
    }

    if (this.LDClientInstances[instanceId].status === 'FAILED_TO_INITIALIZE') {
      return undefined
    }

    return this.LDClientInstances[instanceId].instance
  }

  private removeClientInstance = (project: string = this.defaultProject) => {
    const contextObj = this.getContextObject()
    const LDClientId = this.LDClientIds[project]
    if (!LDClientId) return undefined

    const instanceId = getLaunchDarklyInstanceId(LDClientId, contextObj.key)

    delete this.LDClientInstances[instanceId]
  }

  /**
   * Use this method to override LD with custom feature flags and their values. This should never be used for production code.
   * Useful only for automated tests and local development overrides.
   *
   * this.defaultProject is built in, but you can also bootstrap any other project you registered with `registerLDClientId`
   * You can manually specify the project for when there is more than one like this
   * { platform: { featureA: false, featureB: true }, global: { featureA: false, featureB: true } }
   * Otherwise by default the defaultProject will be used
   * @param projectFlags e.g. { featureA: false, featureB: true }
   */
  public bootstrap = async (projectFlags: { [project: string]: LDClient.LDFlagSet } | LDClient.LDFlagSet) => {
    if (!this.allowBootstrapping) {
      console.error('FORBIDDEN: Cannot call bootstrap in this environment.')
      return
    }

    const contextObj = this.getContextObject()

    if (typeof projectFlags[Object.keys(projectFlags)[0]] === 'object') {
      // Assume project identifier is present when type is object
      for (const project in projectFlags) {
        await this.initializeLDClient(this.LDClientIds[project], contextObj, {
          bootstrap: {
            ...projectFlags[project]
          }
        })
      }
    } else {
      // Assume default project for plain LDFlagSet
      await this.initializeLDClient(this.LDClientIds[this.defaultProject], contextObj, {
        bootstrap: {
          ...projectFlags
        }
      })
    }
  }

  /**
   * Save a flagSet object to local storage with this method and it will be used to bootstrap the next connection - if bootstrapping is enabled.
   * @param ldFlagSet { [flagName: string]: boolean | string } e.g. { flagA: true, flagB: "${global.q3-2020}" }
   * @param TTL number of milliseconds to live. Default is 24 hours (86400000)
   */
  public storeLDFlagSet = (ldFlagSet: LDClient.LDFlagSet, TTL: number = defaultTimeToLive) => {
    const expireDate = Date.now() + TTL
    this.storage.setItem(localStorageId, JSON.stringify({ expireDate, data: ldFlagSet }))
  }

  public removeLDFlagSet = () => {
    this.storage.removeItem(localStorageId)
    this.removeClientInstance()
  }

  /**
   * Retrieves the stored LDFlagSet and returns the data so long as it hasn't expired
   * If the data has expired it will clear the data from local storage
   */
  public getLDFlagSet = (): LDClient.LDFlagSet | null => {
    const result = this.storage.getItem(localStorageId)
    if (result) {
      let parsed = null
      try {
        parsed = JSON.parse(result)
        if (parsed.data && parsed.expireDate && parsed.expireDate > Date.now()) {
          return parsed.data
        } else {
          // If we reach this line the data is either corrupted or expired
          this.removeLDFlagSet()
        }
      } catch (e) {
        console.error(`INVALID JSON in ${localStorageId}`)
        this.removeLDFlagSet()
      }
    }
    return null
  }

  /**
   * **variationByClientKey**
   *
   * A method that implements Release Toggles using LD as prescribed here: https://confluence.navexglobal.com/x/f4PkC
   *
   * Example usage:
   * ```js
   * const getVariations = async () => {
   *      const myFlagVariation = await Global.LaunchDarkly.variationDetail("YOUR_FEATURE_KEY", false)
   *      console.log("Feature Flag variation:", myFlagVariation.value)
   * }
   * await getVariations()
   * ```
   *
   * @param featureKey Your LaunchDarkly project's feature flag key/name.
   * @param defaultVariation OPTIONAL (Defaults to false) Your LaunchDarkly project's feature's default variation.
   * @param project OPTIONAL (Defaults to the defaultProject) e.g. 'global'. The key used to register a custom LDCLientID
   */
  public variationDetail = async (
    featureKey: string,
    defaultVariation: any = false,
    project: string = this.defaultProject,
    _callTrace: string[] = [getCallTraceId(project, featureKey)]
  ): Promise<EvaluationDetail> => {
    let result: LDClient.LDEvaluationDetail = undefined

    const LdClientInstance = await this.getClientInstance(project)

    if (!LdClientInstance) {
      console.error(`ERROR: Unable to connect to Launch Darkly: ${project}`)
      return {
        reason: {
          errorKind: `${project.toUpperCase()}_NOT_CONNECTED`,
          kind: 'ERROR'
        },
        value: defaultVariation,
        variationIndex: null,
        callTrace: _callTrace
      }
    }

    result = LdClientInstance.variationDetail(featureKey, defaultVariation)

    if (!!result.reason && result.reason.kind === 'ERROR') {
      console.error(`ERROR: ${project} flag (${featureKey}): `, result.reason.errorKind)
      return {
        ...result,
        callTrace: _callTrace
      }
    }

    let isLookup = false
    let lookupProject = project
    let lookupFlagName = undefined

    // Check to see if this is a string variation with a pointer to another toggle's value such as '$global:q3-2020'
    const projectFlagLookupMatch = String(result.value).match(projectFlagLookupRegex)
    if (projectFlagLookupMatch !== null) {
      isLookup = true
      // When we drop IE we could just write const { projectName, flagName } = match.groups
      const [_originalValue, projectName, flagName] = projectFlagLookupMatch
      lookupProject = projectName
      lookupFlagName = flagName
    }

    // Check to see if this is a string variation with a pointer to another toggle's value such as `${q3-2020}`
    // This syntax assumes the same project as the originating flag variation call
    const flagNameLookupMatch = String(result.value).match(flagNameLookupRegex)
    if (flagNameLookupMatch !== null) {
      // When we drop IE we could just write const { flagName } = match.groups
      const [_originalValue, flagName] = flagNameLookupMatch
      isLookup = true
      lookupProject = project
      lookupFlagName = flagName
    }

    if (isLookup && lookupProject && lookupFlagName) {
      const callTraceId = getCallTraceId(lookupProject, lookupFlagName)

      if (_callTrace.length > this.lookupLimit) {
        _callTrace.push(callTraceId)
        console.error(`ERROR: Flag lookup limit of (${this.lookupLimit}) exceeded: ${_callTrace.join(' --> ')}`)
        return {
          reason: {
            errorKind: 'EXCEEDED_LOOKUP_LIMIT',
            kind: 'ERROR'
          },
          value: defaultVariation,
          variationIndex: null,
          callTrace: _callTrace
        }
      }

      if (_callTrace.includes(callTraceId)) {
        _callTrace.push(callTraceId)
        console.error(`ERROR: Infinite loop detected in Launch Darkly call trace: ${_callTrace.join(' --> ')}`)
        return {
          reason: {
            errorKind: 'INFINITE_LOOP',
            kind: 'ERROR'
          },
          value: defaultVariation,
          variationIndex: null,
          callTrace: _callTrace
        }
      }

      _callTrace.push(callTraceId)
      result = await this.variationDetail(lookupFlagName, defaultVariation, lookupProject, _callTrace)
    }

    return {
      ...result,
      callTrace: _callTrace
    }
  }

  public variation = async (
    featureKey: string,
    defaultVariation: any = false,
    project: string = this.defaultProject
  ) => {
    const result = await this.variationDetail(featureKey, defaultVariation, project)
    return result.value
  }

  public booleanVariationDetail = async (
    featureKey: string,
    defaultVariation: boolean = false,
    project: string = this.defaultProject
  ) => {
    const result = await this.variationDetail(featureKey, defaultVariation, project)
    result.value = String(result.value).toLowerCase() === 'true'
    return result
  }

  public booleanVariation = async (
    featureKey: string,
    defaultVariation: boolean = false,
    project: string = this.defaultProject
  ) => {
    const result = await this.booleanVariationDetail(featureKey, defaultVariation, project)
    return result.value
  }

  public stringVariationDetail = async (
    featureKey: string,
    defaultVariation: string = 'false',
    project: string = this.defaultProject
  ) => {
    const result = await this.variationDetail(featureKey, defaultVariation, project)
    result.value = String(result.value)
    return result
  }

  public stringVariation = async (
    featureKey: string,
    defaultVariation: string = 'false',
    project: string = this.defaultProject
  ) => {
    const result = await this.stringVariationDetail(featureKey, defaultVariation, project)
    return result.value
  }
}

let preloadedLaunchDarklyToggleStates = {}

export const preloadLaunchDarklyToggleState = async (flagName, defaultValue = false) => {
  preloadedLaunchDarklyToggleStates[flagName] = defaultValue
  const toggleState = await Global.LaunchDarkly.assumed.booleanVariation(flagName, defaultValue)
  preloadedLaunchDarklyToggleStates[flagName] = toggleState
}

export const getPreloadedLaunchDarklyToggleState = (flagName, defaultValue = false) => {
  const toggleState = preloadedLaunchDarklyToggleStates[flagName] || defaultValue
  return toggleState
}
