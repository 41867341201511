import { IGlobal } from '../interfaces/IGlobal';
import { appShellRootUIName, appShellRootUILocalhostDomain } from '../utils/constants'

declare const Global: IGlobal

export const parseHostingKey = (microFrontendName: string) => {
  if (microFrontendName === appShellRootUIName) {
    return appShellRootUIName // folder name in s3
  }
  const extraPartitions = ['_branch_tests', '_integration_tests']
  const flattenedMenuItems = Global.Nav.flattenMenuItems(Global.API.menuItems)
  const microFrontend = flattenedMenuItems.find(x => x.appName === microFrontendName)
  const { url, urlOverrideKey } = microFrontend
  const overrideUrl = Global.Overrides.get(urlOverrideKey)
  const scriptUrl = typeof overrideUrl === 'string' ? overrideUrl : url
  let indexOfSlash = scriptUrl.indexOf('/', 9)
  const path = scriptUrl.substr(indexOfSlash + 1)
  const partitionA = path.substr(0, path.indexOf('/'))
  let hostingKey = partitionA
  if (extraPartitions.indexOf(partitionA) !== -1) {
    indexOfSlash = path.indexOf('/', partitionA.length + 1)
    const partitionB = path.substring(partitionA.length + 1, indexOfSlash)
    hostingKey = `${hostingKey}/${partitionB}`
  }

  return hostingKey
}

const buildCloudBundleUri = (scriptBundleUri: string, area: string, hashedPath: string, useBundleHash: boolean) => {
  const pathIndex = scriptBundleUri.indexOf('/static/js/main.')
  const bundlePath = scriptBundleUri.substr(pathIndex)
  const bundleHash = useBundleHash ? bundlePath.replace('/static/js/main.', '').replace('.js', '') + '/' : ''
  const bundleBase = scriptBundleUri.substr(0, pathIndex)
  let bundleUri = bundleBase
  if (typeof area === 'string' && area.length > 0) {
    bundleUri = `${bundleUri}/${area}`
  }
  bundleUri = `${bundleUri}/${bundleHash}${hashedPath}`
  return bundleUri
}

const buildLocalhostBundleUri = (baseUri: string, path: string) => {
  let portIndex = baseUri.indexOf('localhost:') + 10
  let portText = baseUri.substr(portIndex)
  portIndex = portText.indexOf('/')
  portText = portText.substr(0, portIndex)
  const portNumber = parseInt(portText)
  const bundleUri = `http://localhost:${portNumber}/${path}`
  return bundleUri
}

export const buildBundleUri = (microFrontendName: string, area: string, hashedPath: string, useBundleHash: boolean) => {
  if (microFrontendName === appShellRootUIName) {
    return `/${area}/${hashedPath}`
  }

  const flattenedMenuItems = Global.Nav.flattenMenuItems(Global.API.menuItems)
  const microFrontend = flattenedMenuItems.find(x => x.appName === microFrontendName)

  const { urlOverrideKey, url } = microFrontend
  const overrideUrl = Global.Overrides.get(urlOverrideKey)

  const isOverrideUrl = typeof overrideUrl === 'string'
  const isLocalhostOverrideUrl = isOverrideUrl && overrideUrl.indexOf('http://localhost') === 0
  if (!isLocalhostOverrideUrl) {
    // parse from script bundle url
    const scriptBundleUrl = !isOverrideUrl ? url : overrideUrl
    const contentUri = buildCloudBundleUri(scriptBundleUrl, area, hashedPath, useBundleHash)
    return contentUri
  }
  else {
    // parse from localhost override
    let path = hashedPath
    if (typeof area == 'string' && area.length > 0) {
      path = `${area}/${path}`
    }
    const contentUri = buildLocalhostBundleUri(overrideUrl, path)
    return contentUri
  }
}

export const isLocalhostOverrideEnabled = (microFrontendName) => {
  if (microFrontendName === appShellRootUIName) {
    return window.location.hostname.endsWith(appShellRootUILocalhostDomain)
  }
  const flattenedMenuItems = Global.Nav.flattenMenuItems(Global.API.menuItems)
  const microFrontend = flattenedMenuItems.find(x => x.appName === microFrontendName)
  const { urlOverrideKey } = microFrontend
  const overrideUrl = Global.Overrides.get(urlOverrideKey)
  const isOverrideUrl = typeof overrideUrl === 'string'
  const isLocalhostOverrideUrl = isOverrideUrl && overrideUrl.indexOf('http://localhost') === 0
  return isLocalhostOverrideUrl
}
