import { GlobalLaunchDarkly } from '../globals/globalLaunchDarkly'
import { LaunchDarklyClientId } from '../../config/api.json'
import { isDevelopmentSite } from './devUtils'
import { IGlobal } from '../interfaces/IGlobal'
import { ILaunchDarkly } from '../interfaces/ILaunchDarkly'
import { isNavexAdmin } from './routeUtils'

declare const Global: IGlobal

const warnLdDeprecated = () =>
  console.warn(
    'Global.LaunchDarkly is deprecated, use either Global.LaunchDarkly.signedIn or Global.LaunchDarkly.assumed instead.'
  )

interface BuildLaunchDarklyOptions {
  sendEvents: boolean
}

export const buildLaunchDarkly = (buildOptions?: BuildLaunchDarklyOptions) => {
  const launchDarkly: ILaunchDarkly = {
    signedIn: new GlobalLaunchDarkly({
      clientIds: [{ project: 'platform', clientId: LaunchDarklyClientId }],
      defaultProject: 'platform',
      getContextObject: () => {
        const isNavexAdminUser = isNavexAdmin(window.location.href)
        if (isNavexAdminUser) {
          return Global.Utils.getLaunchDarklyUserObj('navex')
        }
        return Global.Utils.getLaunchDarklyUserObj(Global.User.getClientKey())
      },
      allowBootstrapping: isDevelopmentSite(),
      sendEvents: buildOptions?.sendEvents ?? true
    }),
    assumed: new GlobalLaunchDarkly({
      clientIds: [{ project: 'platform', clientId: LaunchDarklyClientId }],
      defaultProject: 'platform',
      getContextObject: () => Global.Utils.getLaunchDarklyUserObj(),
      allowBootstrapping: isDevelopmentSite(),
      sendEvents: buildOptions?.sendEvents ?? true
    }),
    getContextObject: () => Global.Utils.getLaunchDarklyUserObj(),
    get LDClient() {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.LDClient
    },
    get ContextKey() {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.ContextKey
    },
    get LDClientIds() {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.LDClientIds
    },
    get defaultProject() {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.defaultProject
    },
    registerLDClientId: (...args) => {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.registerLDClientId(...args)
    },
    getClientInstance: (...args) => {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.getClientInstance(...args)
    },
    bootstrap: (...args) => {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.bootstrap(...args)
    },
    storeLDFlagSet: (...args) => {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.storeLDFlagSet(...args)
    },
    removeLDFlagSet: (...args) => {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.removeLDFlagSet(...args)
    },
    getLDFlagSet: (...args) => {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.getLDFlagSet(...args)
    },
    variationDetail: (...args) => {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.variationDetail(...args)
    },
    variation: (...args) => {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.variation(...args)
    },
    booleanVariationDetail: (...args) => {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.booleanVariationDetail(...args)
    },
    booleanVariation: (...args) => {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.booleanVariation(...args)
    },
    stringVariationDetail: (...args) => {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.stringVariationDetail(...args)
    },
    stringVariation: (...args) => {
      warnLdDeprecated()
      return Global.LaunchDarkly.assumed.stringVariation(...args)
    }
  }
  return launchDarkly
}
