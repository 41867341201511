export default class BaseHost {
  public origin: string
  public protocol: string
  public host: string
  public port: string
  public hostname: string

  constructor(url: string) {
    this.origin = url
    const indexOfProtocol = this.origin.indexOf('://')
    this.protocol = this.origin.substr(0, indexOfProtocol + 1)

    const stripProtocol = url => (url.substr(indexOfProtocol + 3))
    this.host = stripProtocol(this.origin)

    const parsePort = () => {
      const indexOfPort = this.host.indexOf(':')
      if (indexOfPort === -1) return null
      const port = this.host.substr(indexOfPort + 1)
      return port
    }
    this.port = parsePort() || ''
    this.hostname = this.host.replace(`:${this.port}`, '')
  }

  isLocal() { return !!this.port }

  buildSubdomainHost() {
    return `${this.protocol}//${this.host}`
  }

  parseHostSubdomain() {
    const origin = window.location.host
    const subdomainFormat = `.${this.host}`
    if (origin.endsWith(subdomainFormat)) {
      const subdomain = origin.replace(subdomainFormat, '')
      if (!!subdomain) {
        return subdomain
      }
    }
    return null
  }
}