import 'core-js/features/url'
import { start } from 'single-spa'
import { GlobalApi } from './globals/globalApi'
import { GlobalUi } from './globals/globalUi'
import { GlobalUtils } from './globals/globalUtils'
import { GlobalCookieUtils } from './globals/globalCookieUtils'
import { GlobalOverrides } from './globals/globalOverrides'
import { GlobalAuthenticatedUser, GlobalUnauthenticatedUser } from './globals/globalUser'
import { GlobalLocalization } from './globals/globalLocalization'
import { preloadLaunchDarklyToggleState } from './globals/globalLaunchDarkly'
import { GlobalNav } from './globals/globalNav'
import { GlobalLogger, NoOpLogger } from './globals/globalLogger'
import { GlobalDevTools } from './globals/globalDevTools'
import { registerMenuDrivenApps } from './utils/singleSpaUtils'
import { verifyHost } from './core/verify-host'
import { isPlatformAdmin, isNavexAdmin as getIsNavexAdmin, isOverrides } from './utils/routeUtils'
import { isMobileBrowser } from './utils/browserUtils'
import { addDevUtils, isDevelopmentSite, getInitialLogLevel } from './utils/devUtils'
import { getClientIdentifierFromUrl } from 'navex'
import { setClockSkew } from './utils/clockSkew'
import { getQueryStringParameter } from './utils/queryStringUtils'
import { groupMenuItemsByAppName } from './utils/groupMenuItemsByAppName'
import { IGlobal } from './interfaces/IGlobal'
import { IWindow } from './interfaces/IWindow'
import { registerPendo } from './pendo'
import { showCriticalError } from './utils/showCriticalError'
import { loadAppShellRootUILangFile, localize } from './utils/localizationUtils'
import { buildUserProfileFromDecodedToken } from './utils/buildUserProfileFromDecodedToken'
import { startSessionStalenessTimer, getDecodedToken, loadTokenResponse } from './utils/apiUtils'
import { buildLaunchDarkly } from './utils/buildLaunchDarkly'
import { appShellRootUIName } from './utils/constants'
import { registerZoomin } from './zoomin'
import { initializeSessionStatusUi } from './sessionStatusUi'

declare const window: IWindow
declare const Global: IGlobal

const langQueryStringParam = getQueryStringParameter(window.location.search, 'lang')
const isNavexAdmin = getIsNavexAdmin(window.location.href)

const cookieUtils = new GlobalCookieUtils()

const authorize = async () => {
  // We redirect to /auth/authorize to perform authentication.
  // If already authenticated, getCustomerInfo will not throw an error.
  console.info('require authentication')
  const currentUrl = new URL(globalThis.location.toString())
  // Capture the current location for the returnUrl from the IdP.
  let requestedUri = ''
  if (currentUrl.searchParams.has('return_uri')) {
    const continueUrl = new URL(currentUrl.searchParams.get('return_uri') || '', currentUrl.origin)
    requestedUri = continueUrl.pathname + continueUrl.search + continueUrl.hash
  } else {
    requestedUri = currentUrl.pathname + currentUrl.search + currentUrl.hash
  }
  const authorizeEndpoint = '/auth/authorize'
  const qs = new URLSearchParams({ requestedUri: requestedUri })
  // Redirect to IdP.
  location.href = `${authorizeEndpoint}?${qs.toString()}`
  // Delay subsequent code to allow the location change to occur.
  await new Promise((r) => setTimeout(r, 5000))
}

const initialize = async () => {
  // Check for csrf token
  if (!isOverrides(window.location.pathname) && !cookieUtils.get('CSRF_TOKEN')) {
    //showCriticalError("Missing CSRF Token") // todo: come back to this, needs to not render when bypassAuth==true is in querystring and deployment is in lower devtooling environment
    //return;
  }

  window.Global = {
    API: new GlobalApi(),
    UI: new GlobalUi(),
    CookieUtils: cookieUtils,
    // TODO See about moving this into line 50's if
    Overrides: new GlobalOverrides(),
    Utils: new GlobalUtils(),
    User: new GlobalUnauthenticatedUser(),
    Localization: new GlobalLocalization(),
    LaunchDarkly: buildLaunchDarkly(),
    __LaunchDarklyNoEvents: buildLaunchDarkly({ sendEvents: false }),
    Nav: new GlobalNav(),
    Log: new NoOpLogger()
  }

  if (isDevelopmentSite() === true) {
    window.Global.DevTools = new GlobalDevTools()
    window.Global.Log = new GlobalLogger()
    window.Global.Log.setLevel(window.Global.Log.Levels[getInitialLogLevel()])
  }

  Global.UI.showLoadingPanel(appShellRootUIName)

  // Attempt/perform silent authentication.
  // Cookies will be adjusted if the user is not authenticated.
  let silentAuthOptions: RequestInit = {
    mode: 'no-cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json'
    }
  }
  await globalThis.fetch('/auth/silent-auth', silentAuthOptions)

  if (isNavexAdmin) {
    Global.CookieUtils.remove('AdminClientKey')
    Global.CookieUtils.remove('AdminTenantAlias')
    Global.CookieUtils.remove('AdminTenantId')
  } else if (!Global.Utils.getTenantAlias()) {
    // We need the API manifest in order to call Customer Manager
    await Global.API.loadMenuItemsAndApiBaseUriList(true)
    const clientIdentifierInUrl = getClientIdentifierFromUrl(window.location.href)
    Global.Utils.setCookie('AdminTenantAlias', clientIdentifierInUrl)
    // For NavexOne (not Platforminator), the AdminTenant cookies must be set before the frontend can read LaunchDarkly
    // toggles because the LaunchDarkly.signedIn() component reads the AdminClientKey cookie
    try {
      await Global.Utils.getCustomerInfoAndSetCookies(true)
    } catch (err) {
      // Clear any Customer Info cookies set while unauthenticated
      Global.Utils.clearCustomerInfoCookies()
      await authorize()
      return
    }
  }

  const auth0ToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('AUTH0_CUTOVER', false)
  const asmToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('Auth0_ASM_Cutover', false)
  const ssuiToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('N1_Appshell1_SSUI', false)

  // Allow url parameter to set the language
  if (langQueryStringParam) {
    // If there was a "lang" querystring parameter, update the current
    // locale which in turn also updates the html tag's lang attribute
    window.Global.Localization.setCurrentLocale(langQueryStringParam)
    const redirectUrl = window.location.href
      .replace(`?lang=${langQueryStringParam}&`, '?')
      .replace(`?lang=${langQueryStringParam}`, '')
      .replace(`&lang=${langQueryStringParam}`, '')
    window.history.pushState({}, '', redirectUrl)
  } else {
    // If there wasn't a "lang" querystring parameter, just set the
    // html tag's lang attribute
    const currentLocale = Global.Localization.getCurrentLocale()
    Global.Localization.setHtmlTagLangAttribute(currentLocale)
  }

  // When other Navex apps link to this, they will need to pass in a client key
  // Ex: When EP Admin links to Mobile Intake Admin, it needs to pass a client key
  const navexAdminClientKey = getQueryStringParameter(window.location.search, 'NavexAdminClientKey')
  if (navexAdminClientKey) {
    window.Global.Utils.clearCustomerInfoCookies()
    window.Global.Utils.setCookie('AdminClientKey', navexAdminClientKey)
    const redirectUrl = window.location.href
      .replace(`?NavexAdminClientKey=${navexAdminClientKey}&`, '?')
      .replace(`?NavexAdminClientKey=${navexAdminClientKey}`, '')
      .replace(`&NavexAdminClientKey=${navexAdminClientKey}`, '')
    window.history.pushState({}, '', redirectUrl)
  }

  const navexAdminTenantAlias = getQueryStringParameter(window.location.search, 'NavexAdminTenantAlias')
  if (navexAdminTenantAlias) {
    window.Global.Utils.clearCustomerInfoCookies()
    window.Global.Utils.setCookie('AdminTenantAlias', navexAdminTenantAlias)
    const redirectUrl = window.location.href
      .replace(`?NavexAdminTenantAlias=${navexAdminTenantAlias}&`, '?')
      .replace(`?NavexAdminTenantAlias=${navexAdminTenantAlias}`, '')
      .replace(`&NavexAdminTenantAlias=${navexAdminTenantAlias}`, '')
    window.history.pushState({}, '', redirectUrl)
  }

  // Check for missing alias
  if (!Global.Utils.getTenantAlias() && isPlatformAdmin(window.location.href)) {
    await loadAppShellRootUILangFile()
    const missingTenantAliasMessage = localize('MISSING_TENANT_ALIAS')
    const exampleText = localize('EXAMPLE_ABBREVIATED')
    const tenantAliasText = localize('TENANT_ALIAS_NO_SPACES')
    showCriticalError(
      `<p>${missingTenantAliasMessage}</p><p>${exampleText} ${location.origin}/<b>${tenantAliasText}</b>/</p>`
    )
    return
  }

  if (isMobileBrowser()) {
    document.body.className = 'mobile-on'
  }

  verifyHost()

  if (isDevelopmentSite()) {
    addDevUtils()
  }

  await loadLangFileAndManifestAndLaunchDarkly()
  Global.Log.debug(`initial api calls are complete`)

  if (!isOverrides(window.location.pathname)) {
    Global.Log.debug(`Initializing appshell bff session engine`)
    // Request the full response from getDecodedToken so we can pass the server time to setClockSkew
    const decodedTokenResponse = await getDecodedToken(auth0ToggleEnabled, asmToggleEnabled, true)
    if (!decodedTokenResponse) {
      const missingIdToken = localize('MISSING_ID_TOKEN')
      showCriticalError(`<p>${missingIdToken}</p>`)
      return
    }
    const decodedToken = decodedTokenResponse.data

    const tokenResponse = await loadTokenResponse({
      withAccessToken: true,
      auth0ToggleEnabled,
      asmToggleEnabled,
      checkToggle: false,
      skipActivityCheck: false,
    })
    if (tokenResponse.status === 401) {
      await authorize()
      return
    }

    Global.Log.debug(`identity token has been decoded`)

    if (!isBypassingDevelopmentSiteAuth() && !ssuiToggleEnabled) {
      await setClockSkew(decodedTokenResponse.headers.date)
      await startSessionStalenessTimer(auth0ToggleEnabled, asmToggleEnabled)
    }

    // Set up user profile
    const userProfile = buildUserProfileFromDecodedToken(decodedToken)
    window.Global.User = new GlobalAuthenticatedUser(userProfile)
    Global.Log.debug(`global user has been constructed`)

    onAfterLogin()
    return
  } else {
    registerAndStart()
  }
}

const onAfterLogin = async () => {
  Global.Log.info(`finalizing initialization of logged-in user`)
  // The locale for PlatformAdmin users should come from the user's profile. (See SSO-7976)
  const userLocale = Global.User.getLocale()
  if (!langQueryStringParam) {
    if (userLocale !== Global.Localization.getCurrentLocale()) {
      // Set the locale to the one in the user's profile
      Global.Localization.setCurrentLocale(userLocale)

      // Reload some things now that the locale has changed
      await loadLangFileAndManifestAndLaunchDarkly()
    }
  }
  registerAndStart()
}

const loadLangFileAndManifestAndLaunchDarkly = async () => {
  // These need to be loaded after calling Global.Localization.setCurrentLocale()
  await Promise.all([
    loadAppShellRootUILangFile(),
    Global.API.loadMenuItemsAndApiBaseUriList(false),
    preloadLaunchDarklyToggleState('SingleDomainPT'),
    preloadLaunchDarklyToggleState('CIA_CM_TenantAlias'),
    preloadLaunchDarklyToggleState('CIA_AdminSettings_UseAlias')
  ])
}

const isBypassingDevelopmentSiteAuth = () => {
  return (
    typeof window.Global.DevTools === 'object' &&
    !!window.Global.DevTools &&
    window.Global.DevTools.isBypassingDevelopmentSiteAuth() === true
  )
}

const shouldBlockAccess = () => {
  if (isBypassingDevelopmentSiteAuth()) {
    return false
  }
  const userRole = Global.User.getRole()
  const allowedRoles = Global.API.manifestResponse.ui.allowedRoles

  if (!!allowedRoles && allowedRoles.indexOf(userRole) < 0) {
    return true
  }
  return false
}

const showBlockedAccessScreen = async () => {
  const signoutLinkId = 'not_authorized_signout'

  const message = `
    ${localize('NOT_AUTHORIZED')} -
    <a
      id="${signoutLinkId}"
      href="#"
    >
    ${localize('SIGN_OUT')}
    </a>
  `
  showCriticalError(message)

  // Add click event to signout link
  const signoutLink = document.getElementById(signoutLinkId)
  signoutLink.addEventListener('click', () => {
    /**
     * Passing false to intentionally use the root-ui loading spinner instead of
     * the contentLoadingHandler's spinner since this transition is unloading the
     * the entire appshell UI not just a micro ui
     */
    Global.UI.showLoadingPanel(appShellRootUIName, false)
    Global.User.signOut()
  })
}

const showMissingManifestScreen = async () => {
  const message = localize('MISSING_PROJECT_MANIFEST')
  showCriticalError(message)
}

const registerAndStart = async () => {
  await Global.Utils.getCustomerInfoAndSetCookies(false)
  Global.UI.hideLoadingPanel(appShellRootUIName)
  if (!Global.API.menuItems) {
    showMissingManifestScreen()
  } else if (shouldBlockAccess()) {
    showBlockedAccessScreen()
  } else {
    const menuItems = Global.API.getMenuItems()
    const flattenedMenuItems = Global.Nav.flattenMenuItems(menuItems)
    const menuItemsGroupedByAppName = groupMenuItemsByAppName(flattenedMenuItems)
    await registerMenuDrivenApps(menuItemsGroupedByAppName)
    start()
    const asmToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('Auth0_ASM_Cutover', false)
    const ssuiToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('N1_Appshell1_SSUI', false)
    if (asmToggleEnabled && ssuiToggleEnabled) {
      const currentLocale = Global.Localization.getCurrentLocale()
      initializeSessionStatusUi(currentLocale)
    }
    // Do not register Pendo for navexadmin as it's internal and doesn't need tracking
    if (!isNavexAdmin) {
      registerPendo()
      registerZoomin()
    }
  }
}

initialize()
