import { IGlobal } from "../interfaces/IGlobal"
import { isDevelopmentSite } from "./devUtils"

declare const Global: IGlobal

export const wipFlagExistsInOverrides = (wipFlagId) => {
  return isDevelopmentSite() && Global.Overrides.get(wipFlagId) === "true"
}

export const wipFlagExistsInQueryString = (wipFlagId) => {
  if (!isDevelopmentSite()) {
    return false
  }

  const qsWipFlags = Global.Utils.getQueryStringParameter(window.location.href, "WipFlags")
  if (qsWipFlags) {
    let flags = qsWipFlags.split(',')
    return flags.indexOf(wipFlagId) > -1
  }

  return false
}