import { buildBundleUri, isLocalhostOverrideEnabled, parseHostingKey } from '../utils/bundleUtils'
import { IManifest } from '../interfaces/IManifest';
import { IWindow } from '../interfaces/IWindow';
import { IGlobal } from '../interfaces/IGlobal';
import { resxToJson } from '../utils/resxToJson';
import settings from '../core/settings'
import { IInvokeApiOptions } from '../interfaces/IInvokeApiOptions';

const i18n = require('roddeh-i18n')
const languageFiles = {} // This grows each time loadLanguageFile() gets called
const defaultLanguageCode = "en-US"
let currentLocale = undefined

declare const window: IWindow
declare const Global: IGlobal

const loadFromCloudJsonLangfile = async (microFrontendName: string, langBundleName: string, langCode: string) => {
  const hostingKey = parseHostingKey(microFrontendName)
  const langUri = `${settings.LanguageFilesBaseUri}/${hostingKey}/${langBundleName}/${langCode}.json`
  const options: IInvokeApiOptions = {
    authRequired: false,
    method: 'GET',
    url: langUri
  }
  const response = await Global.API.invokeApi(options)
  return (response.status === 200) ? response.data : null
}

const loadFromLocalhostResxFile = async (microFrontendName: string, langBundleName: string, useBundleHash: boolean) => {
  const langUri = buildBundleUri(microFrontendName, 'localization', `${langBundleName}.resx`, useBundleHash)
  const options: IInvokeApiOptions = {
    authRequired: false,
    method: 'GET',
    url: langUri
  }
  const response = await Global.API.invokeApi(options)
  return (response.status === 200) ? resxToJson(response.data) : null
}

const loadFromLocalhostMockTranslationJsonFile = async (microFrontendName: string, langBundleName: string, langCode: string) => {
  const langUri = buildBundleUri(microFrontendName, 'localization_mocktranslations', `${langBundleName}/${langCode}.json`, false)
  const options: IInvokeApiOptions = {
    authRequired: false,
    method: 'GET',
    url: langUri
  }
  const response = await Global.API.invokeApi(options)
  return (response.status === 200) ? response.data : null
}

export class GlobalLocalization {
  public manifests: IManifest[] | undefined = undefined

  async loadLanguageFile(microFrontendName: string, langBundleName: string, activeLanguages?: string[], locale?: string) {
    const langCode = locale || Global.Localization.getCurrentLocale(activeLanguages)
    let values = undefined
    if (isLocalhostOverrideEnabled(microFrontendName)) {
      if (langCode === defaultLanguageCode) {
        values = await loadFromLocalhostResxFile(microFrontendName, langBundleName, false)
      } else {
        values = await loadFromLocalhostMockTranslationJsonFile(microFrontendName, langBundleName, langCode)
      }
    }
    if (!values) {
      // First look for the json file in langfiles cdn
      values = await loadFromCloudJsonLangfile(microFrontendName, langBundleName, langCode)
    }

    languageFiles[langBundleName] = i18n.create({ values })
  }

  localize(langBundleName: string, keyName: string, args?: any) {
    return languageFiles[langBundleName](keyName, args)
  }

  setCurrentLocale(locale: string) {
    currentLocale = locale
    this.setHtmlTagLangAttribute(locale)
  }

  getCurrentLocale(activeLanguages?: string[]) {
    this.removeLegacyLocaleFromLocalStorage()

    if (!currentLocale) {
      const userLocale = Global.User.getLocale()
      if (userLocale) {
        this.setCurrentLocale(userLocale)
      }
      else {
        currentLocale = window.navigator.language
        if (activeLanguages && activeLanguages.indexOf(currentLocale) < 0) {
          currentLocale = defaultLanguageCode
        }
      }
    }

    return currentLocale
  }

  getDefaultLanguageCode() {
    return defaultLanguageCode
  }

  setHtmlTagLangAttribute(locale) {
    document.getElementsByTagName('html')[0].lang = locale
  }

  removeLegacyLocaleFromLocalStorage = () => {
    // We used to store the locale in localStorage. But when you use
    // a browser that has third-party cookies disabled, and then try to
    // load this page inside of an iframe (such as with the MyProfile iframe)
    // then the browser prevents access to localstorage and throws an exception
    // This method tries to clean out old locales that were saved
    // to localStorage by previous versions of this file.
    try {
      window.localStorage.removeItem('locale')
    } catch (ex) {
      // If it failed, we must be loading the MyProfile iframe
      // in a browser with that has third-party cookies disabled
      // so just ignore the error
    }
  }
}
