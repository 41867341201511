import { IGlobal } from '../interfaces/IGlobal';

declare const Global: IGlobal

export class GlobalOverrides {
  set(overrideName: string, overrideValue: string) {
    //Overrides will expire after a day. This is open for discussion.
    // pass undefined domain to pin cookies to the exact domain they are set from
    const expiresInDays = 1
    return Global.CookieUtils.set(overrideName, overrideValue, undefined, expiresInDays)
  }

  get(overrideName: string) {
    return Global.CookieUtils.get(overrideName)
  }

  remove(overrideName: string) {
    // cookies use undefined domain to pin them to the exact domain they are set from
    return Global.CookieUtils.remove(overrideName, undefined)
  }
}
