import { IGlobal } from '../interfaces/IGlobal';
import { IMenuItem } from '../interfaces/IMenuItem';
import { getPlatformRoleFromUrl } from '../../backends/auth-shared/lib/navex'
import { IGroupedMenuItem } from './groupMenuItemsByAppName';

declare const Global: IGlobal

const getCurrentRoute = () => {
  return window.location.pathname
}

const removeEndingSlash = (value) => {
  if (value.endsWith('/')) {
    return value.substr(0, value.length - 1)
  }
  return value
}

export const routeStartsWith = (value: string) => {
  const route = getCurrentRoute()
  return removeEndingSlash(route).startsWith(removeEndingSlash(value))
}

export const routeEndsWith = (value: string) => {
  const route = getCurrentRoute()
  return removeEndingSlash(route).endsWith(removeEndingSlash(value))
}

export const routeEquals = (value: string) => {
  const route = getCurrentRoute()
  return removeEndingSlash(route) === removeEndingSlash(value)
}

// TODO: Move some of this logic to the manifests someday
export const requiresAuth = (pathname: string) => {
  if (isOverrides(pathname)) {
    Global.Log.debug(`${pathname} path does not require auth (overrides)`)
    return false
  }

  // Check the manifest
  const authenticationType = getManifestAuthenticationType(Global.API.menuItems)
  const manifestRequiresAuthForCurrentRoute = typeof authenticationType === 'string'
  if (manifestRequiresAuthForCurrentRoute) {
    Global.Log.debug(`${pathname} path does require auth (manifests)`)
    return true
  }

  Global.Log.debug(`${pathname} path does NOT require auth (manifests)`)
  return false // this is dangerous now that all non-devtool appshell things require auth
}

export const getManifestAuthenticationType = (menuItems: IMenuItem[]) => {
  for (let menuItem of menuItems) {
    if (Array.isArray(menuItem.items)) {
      return getManifestAuthenticationType(menuItem.items)
    }
    if (typeof menuItem.authenticationType === 'string') {
      const routes: IGroupedMenuItem['routes'] = [
        { path: '/' + menuItem.path, exact: false }
      ]
      if (menuItem.aliasPaths) {
        routes.concat(menuItem.aliasPaths.map(p => ({
          path: '/' + p,
          exact: menuItem.exact === true
        })))
      }
      if (doesRouteMatch(routes)) {
        return menuItem.authenticationType
      }
    }
  }
  return undefined
}

export const siteUsesSidebar = (url: string) => {
  const role = getPlatformRoleFromUrl(url)
  return role === 'admin' || role === 'navexadmin'
}

export const isPlatformAdmin = (url: string) => {
  return getPlatformRoleFromUrl(url) === 'admin'
}

export const isNavexAdmin = (url: string) => {
  return getPlatformRoleFromUrl(url) === 'navexadmin'
}

export const isOverrides = (pathname: string) => {
  return pathname.endsWith('/overrides/') || pathname.endsWith('/overrides')
}

export const doesRouteMatch = (routes: IGroupedMenuItem['routes']) => {
  for (const route of routes) {
    if (route.exact ? routeEquals(route.path) : routeStartsWith(route.path)) {
      return true
    }
  }
  return false
}
