import { IGlobal } from "../interfaces/IGlobal";
import { appShellRootUIName } from "./constants";

declare const Global: IGlobal

export const loadAppShellRootUILangFile = async () => {
    const microfrontendName = appShellRootUIName
    const languageBundleName = 'appshell-root-ui'
    await Global.Localization.loadLanguageFile(microfrontendName, languageBundleName)
}

export const localize = (keyName: string, args?: any) =>
  Global.Localization.localize(appShellRootUIName, keyName, args)
