import { LifeCycles, registerApplication } from 'single-spa'
import { siteUsesSidebar, doesRouteMatch } from './routeUtils'
import { isDevelopmentSite } from './devUtils';
import _cloneDeep from 'lodash/cloneDeep'
import { IMenuItem } from '../interfaces/IMenuItem';
import { IGlobal } from '../interfaces/IGlobal';
import { showCriticalError } from './showCriticalError';
import { getClientIdentifierFromUrl, getPlatformRoleFromUrl } from 'navex'
import { appShellRootUIName } from './constants';
import { IGroupedMenuItem } from './groupMenuItemsByAppName';

declare const Global: IGlobal

export const registerMenuDrivenApps = async (menuItems: IGroupedMenuItem[]) => {
  for (let menuItem of menuItems) {
    if (menuItem.integrationType == "singlespa") {
      if (isDevelopmentSite() && !menuItem.urlOverrideKey) {
        throw new Error(`Development site manifests were built without devtools enabled. `
          + `Rebuild manifests for this site with devtools enabled to make this error go away.`)
      }
      const appName = menuItem.appName
      const url = menuItem.url
      const routes: IGroupedMenuItem['routes'] = menuItem.routes.map(x => ({ path: "/" + x.path, exact: x.exact }))
      const showInMenu = menuItem.showInMenu !== false
      const exact = menuItem.exact === true
      registerDynamicApp(appName, url, routes, showInMenu, exact)
    }
  }
}

/* This function is a very simplistic promise-based "module loader" that allows you to script tag a child application. It is an alternative to using a
 *  module loader like SystemJS that is pretty simple but not as fancy (since it depends on global variables).
 *  How it works is it wraps the process of script tagging a javascript file into a promise. Once the `<script>` tag has executed,
 *  it looks for a global variable that was created by the javascript code and hands that to single-spa as the child application.
 * What this means is that the child application itself will need to create the global variable.
 * Source: https://github.com/CanopyTax/single-spa/issues/110
 */
export const scriptTagApp = (url: string, globalVarName: string): Promise<LifeCycles<{}>> => {
  return new Promise((resolve, reject) => {
    const scriptEl = document.createElement('script');
    scriptEl.src = url;
    scriptEl.async = true;
    scriptEl.onload = () => {
      Global.UI.hideLoadingPanelWithDelay(appShellRootUIName) // Loading panel gets shown in registerDynamicApp()
      resolve(window[globalVarName])
    }
    scriptEl.onerror = (err) => {
      // Fail with a useful error message for developers
      Global.UI.hideLoadingPanelWithDelay(appShellRootUIName)
      let errorMessage = `<p>Could not load <b>${globalVarName}</b></p>`
      if (isDevelopmentSite()) {
        errorMessage += `<p>URL Attempted: <b>${url}</b></p>`
        errorMessage += `<p>If the localhost override is enabled, make sure the microfrontend is running locally.</p>`
      }
      showCriticalError(errorMessage)
      const emptyMicrofrontend = {
        bootstrap: () => new Promise<void>(resolve => resolve()),
        mount: () => new Promise<void>(resolve => resolve()),
        unmount: () => new Promise<void>(resolve => resolve())
      }
      resolve(emptyMicrofrontend)
    }
    document.head.appendChild(scriptEl);
  });
}

export const registerDynamicApp = (appName: string, url: string, routes: IGroupedMenuItem['routes'], showInMenu: boolean, exact: boolean) => {
  //console.log(`Registering app: "${appName}" from "${url}" with "${overrideKey}" on`, paths)
  registerApplication(
    appName,
    () => {
      Global.UI.showLoadingPanel(appShellRootUIName) // Loading panel gets hidden in scriptTagApp()
      return new Promise<LifeCycles<{}>>(resolve => {
        const appPromise = scriptTagApp(url, appName)
        resolve(appPromise)
      })
    },
    () => {
      if (routes[0].path.indexOf("/overrides") > -1) {
        return true
      }

      const doesSiteUseSidebar = siteUsesSidebar(window.location.href)
      const appShellNavigationUIisReady = !!Global.Utils.getIsAdminContainerReady()

      // Usually for things that go inside of AppShellNavigation
      if (doesSiteUseSidebar && showInMenu) {
        if (appShellNavigationUIisReady && doesRouteMatch(routes)) {
          return true
        }
        return false
      }

      // Usually matches AppShellNavigationUI
      return doesRouteMatch(routes)
    }
  )
}

/**
 * Filters a nested list of menu items and only returns items that either
 * don't have a wipFlag, or those that have a wipFlag and that wipFlag
 * is enabled for that user
 */
export const filterMenuItemsWithWipFlag = (menutItems: IMenuItem[]) => {
  const menuItemsCloned = _cloneDeep(menutItems)
  const menuItemsFiltered: IMenuItem[] = []
  for (let menuItem of menuItemsCloned) {
    if (!menuItem.wipFlag || Global.Utils.wipFlagExists(menuItem.wipFlag)) {
      if (menuItem.items) {
        // Process submenus recursively
        menuItem.items = filterMenuItemsWithWipFlag(menuItem.items)
      }
      menuItemsFiltered.push(menuItem)
    }
  }
  return menuItemsFiltered
}

export const fixSidebarPaths = (menuItems: IMenuItem[]) => {
  const clientIdentifier = getClientIdentifierFromUrl(window.location.href)
  const platformRole = getPlatformRoleFromUrl(window.location.href)

  const knownPathRoles = ['admin', 'home']
  const route = window.location.pathname
  const pathSegments = route.split('/')
  const pathRole = pathSegments[1]
  const pathPrefix = knownPathRoles.includes(pathRole) ? pathRole : clientIdentifier

  return menuItems.map(menuItem => {
    // Process submenus recursively
    if (menuItem.items) {
      menuItem.items = fixSidebarPaths(menuItem.items)
    } else {
      if (menuItem.path !== "overrides") {
        if (platformRole === 'navexadmin') {
          menuItem.path = menuItem.path
        } else if (platformRole === 'admin' || platformRole === 'user') {
          menuItem.path = `${pathPrefix}/${menuItem.path}`
        } else {
          // Turn a path like "theme" into a path like "{clientkey}/theme"
          menuItem.path = `${pathPrefix}/${Global.Utils.getAdminArea()}/${menuItem.path}`
        }
      }
    }
    return menuItem
  })
}
