import { IGlobal } from '../interfaces/IGlobal'
import { IUserProfile } from '../interfaces/IUserProfile'
import settings from '../core/settings'
import { appShellRootUIName } from '../utils/constants'
import { isNavexAdmin } from '../utils/routeUtils'

declare const Global: IGlobal
let userProfile

export interface IGlobalUser {
  displayName: () => string
  signOut: () => Promise<any>
  getPuid: () => string
  getRole: () => string
  getLocale: () => string
  getClientKey: () => string
  getTenantId: () => string
  getFirstName: () => string
  getMiddleName: () => string
  getLastName: () => string
  getOidcPreferredUsername: () => string
  getUsername: () => string
  getEmail: () => string
  getEmailVerified: () => boolean
}

export class GlobalUnauthenticatedUser implements IGlobalUser {
  isAuthenticated() {
    return false
  }

  displayName() {
    return 'unknown user'
  }

  getFirstName() {
    return 'unknown'
  }

  getClientKey() {
    return null
  }

  getTenantId() {
    return null
  }

  getMiddleName() {
    return null
  }

  getLastName() {
    return null
  }

  getPuid() {
    return null
  }

  getOidcPreferredUsername() {
    return null
  }

  getUsername() {
    return null
  }

  getEmail() {
    return null
  }

  getEmailVerified() {
    return null
  }

  getRole() {
    return null
  }

  getLocale() {
    return ''
  }

  async signOut() {
    return
  }
}

export class GlobalAuthenticatedUser implements IGlobalUser {
  constructor(authenticatedUserProfile: IUserProfile) {
    userProfile = authenticatedUserProfile
  }

  displayName() {
    const profile = userProfile
    let displayName = `${profile.firstName} ${profile.lastName}`
    return displayName
  }

  getFirstName() {
    return userProfile.firstName
  }

  getMiddleName() {
    return (
      userProfile.attributes &&
      Array.isArray(userProfile.attributes.middle_name) &&
      userProfile.attributes.middle_name.length > 0 &&
      userProfile.attributes.middle_name[0]
    )
  }

  getOidcPreferredUsername() {
    return (
      userProfile.attributes &&
      Array.isArray(userProfile.attributes.preferred_username) &&
      userProfile.attributes.preferred_username.length > 0 &&
      userProfile.attributes.preferred_username[0]
    )
  }

  getLastName() {
    return userProfile.lastName
  }

  getUsername() {
    return userProfile.username
  }

  getEmail() {
    return userProfile.email
  }

  getEmailVerified() {
    return userProfile.emailVerified
  }

  getPuid() {
    return userProfile.attributes.puid[0]
  }

  getClientKey() {
    return userProfile.attributes.clientkey[0]
  }

  getTenantId() {
    return userProfile.attributes.tenantid[0]
  }

  getRole() {
    return userProfile.attributes.user_store_role[0]
  }

  // Not to be confused with Global.Localization.getCurrentLocale()
  getLocale() {
    return userProfile.attributes.locale[0]
  }

  async signOut() {
    Global.UI.showLoadingPanel(appShellRootUIName)
    const auth0ToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('AUTH0_CUTOVER')
    const asmToggleEnabled = await Global.LaunchDarkly.signedIn.booleanVariation('Auth0_ASM_Cutover')

    let returnUrl

    if (auth0ToggleEnabled || asmToggleEnabled) {
      const baseUri = new URL(document.documentElement.baseURI)
      const isNavexAdminUser = isNavexAdmin(baseUri.toString())
      returnUrl = isNavexAdminUser ? baseUri.pathname.replace(/\/$/, '') : baseUri.pathname.replace(/\/+$/, '')
    } else {
      returnUrl = encodeURIComponent(window.location.href)
    }

    if (settings.IsDoormanInEnvironment && !auth0ToggleEnabled && !asmToggleEnabled) {
      returnUrl = `${Global.Utils.getDoormanBaseUrl()}/logoutsuccess/1?returnurl=${returnUrl}`
    }

    window.location.replace(`/auth/sign-out?returnUrl=${returnUrl}`)
  }
}
