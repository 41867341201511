import { set, get, remove, CookieAttributes } from 'js-cookie'

export class GlobalCookieUtils {
  set(
    cookieName: string,
    cookieValue: string,
    domain: string,
    expiresInDays: number,
    secure?: boolean,
    sameSite?: string
  ) {
    set(cookieName, cookieValue, {
      domain,
      expires: expiresInDays,
      secure: secure || true,
      sameSite: sameSite || 'lax'
    })
  }

  get(cookieName: string) {
    return get(cookieName)
  }

  remove(cookieName: string, domain?: string) {
    return remove(cookieName, {
      domain
    })
  }

  extendExpiration(cookieName: string, expiresInDays: number) {
    const cookieValue = get(cookieName)
    set(cookieName, cookieValue, {
      expires: expiresInDays
    })
  }
}
