import { IMenuItem, IMenuItemLaunchDarklySettings } from '../interfaces/IMenuItem';
import { IGlobal } from '../interfaces/IGlobal';
import _cloneDeep from 'lodash/cloneDeep'
import { IGlobalLaunchDarkly } from '../interfaces/IGlobalLaunchDarkly';
import { isNavexAdmin } from '../utils/routeUtils';

declare const Global: IGlobal

const isNavexAdminUser = isNavexAdmin(window.location.href)

export class GlobalNav {

  async loadLaunchDarklyToggleStates(menuItems: IMenuItem[]) {
    const featureFlags = findLaunchDarklyFeatureFlags(menuItems)
    const toggleStates = await Promise.all(featureFlags.map(async x => {
      const globalLaunchDarklyInstance = (isNavexAdminUser && x.isGlobal)
        ? Global.LaunchDarkly.signedIn
        : Global.LaunchDarkly.assumed

      return ({
        featureFlag: x.featureFlag,
        toggleState: await globalLaunchDarklyInstance.booleanVariation(x.featureFlag, false)
      })
    }))
    return toggleStates
  }

  filterSideMenuItemsByToggle(menuItems: IMenuItem[], toggleStates: any[]) {
    const filteredMenuItems: IMenuItem[] = []
    for (let menuItem of menuItems) {
      if (Array.isArray(menuItem.items)) {
        menuItem.items = this.filterSideMenuItemsByToggle(menuItem.items, toggleStates)
      }

      if (typeof menuItem.launchDarkly !== 'object') {
        filteredMenuItems.push(menuItem)
        continue
      }

      const { featureFlag, toggleOnBehavior } = menuItem.launchDarkly
      const { toggleState } = toggleStates.find(x => x.featureFlag === featureFlag)
        || { toggleState: false } // missing toggle treated as OFF

      // when toggle is on and behavior is show, menu item is filtered IN
      // when toggle is off and behavior is hide, menu item is filtered IN
      // all other cases, menu item is filtered OUT
      if ((toggleState === true && toggleOnBehavior === 'show')
        || (toggleState !== true && toggleOnBehavior === 'hide')) {
        filteredMenuItems.push(menuItem)
      }
    }
    return filteredMenuItems
  }

  flattenMenuItems(menuItems: IMenuItem[]) {
    return flattenMenuItemsRecursive(menuItems)
  }
}

const findLaunchDarklyFeatureFlags = (menuItems: IMenuItem[]) => {
  const launchDarklyMenuToggles: IMenuItemLaunchDarklySettings[] = []
  for (let menuItem of menuItems) {
    if (Array.isArray(menuItem.items)) {
      const nestedToggles = findLaunchDarklyFeatureFlags(menuItem.items)
      for (let nestedToggle of nestedToggles) {
        const toggleIndex = launchDarklyMenuToggles.findIndex(x => x === nestedToggle)
        if (toggleIndex === -1) {
          launchDarklyMenuToggles.push(nestedToggle)
        }
      }
    }

    if (typeof menuItem.launchDarkly !== 'object') {
      continue
    }

    const { launchDarkly } = menuItem
    const toggleIndex = launchDarklyMenuToggles.findIndex(x => x.featureFlag === launchDarkly.featureFlag)
    if (toggleIndex === -1) {
      launchDarklyMenuToggles.push(menuItem.launchDarkly)
    }
  }
  return launchDarklyMenuToggles
}

const flattenMenuItemsRecursive = (menuItems: IMenuItem[]) => {
  const flattenedMenuItems: IMenuItem[] = []
  const menuItemsCloned = _cloneDeep(menuItems)

  for (const menuItem of menuItemsCloned) {
    if (!menuItem.items) {
      flattenedMenuItems.push(menuItem)
    } else {
      const flattenedSubMenuItems = flattenMenuItemsRecursive(menuItem.items)
      for (const subMenuItem of flattenedSubMenuItems)
        flattenedMenuItems.push(subMenuItem)
    }
  }

  return flattenedMenuItems
}
