export const getQueryStringParameters = (url: string) => {
  let search = url.split('?')[1];
  return search ?
    search.split('#')[0]
      .split('&')
      .map(param => param.replace('=', '&').split('&'))
      .reduce((map, value) => {
        map[value[0]] = decodeURIComponent(value[1]);
        return map;
      }, {}) : {};
}

export const getQueryStringParameter = (url: string, paramName: string) => {
  return getQueryStringParameters(url)[paramName];
}