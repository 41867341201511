import { parseString } from 'xml2js'

export const resxToJson = (resx) => {
  return new Promise(resolve => {
    parseString(resx, (error, result) => {
      if (error) {
        console.log(error)
        resolve({})
      }
      const entries = result.root.data
      const entriesLang = {}
      entries.forEach(entry => {
        entriesLang[entry['$'].name] = entry.value[0].replace(/\\n/g, "\n")
      })

      resolve(entriesLang)
    })
  })
}