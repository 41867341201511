import BaseHost from './BaseHost'
import { IConfig } from '../interfaces/IConfig'
var hosting = require('../../config/hosting.json')
var api = require('../../config/api.json')

const normalizeUrl = (url: string) => {
  while (url.endsWith('/')) {
    url = url.substr(0, url.length - 1)
  }
  return url
}

const config: IConfig = {
  LandingPageWildcardUri: normalizeUrl(hosting.LandingPageBaseUri),
  LandingPageIFrameWildcardUri: normalizeUrl(hosting.LandingPageIFrameBaseUri),
  CmadUiWildcardUri: normalizeUrl(hosting.CmadUiBaseUriTemplate.replace('{0}', '*')),
  PlatformCmadUiBaseUriTemplate: normalizeUrl(hosting.PlatformCmadUiBaseUriTemplate.replace('{0}', '*')),
  GatewayUiBaseUriTemplate: normalizeUrl(hosting.GatewayUiBaseUriTemplate.replace('{0}', '*')),
  DoormanBaseUrl: normalizeUrl(hosting.DoormanBaseUrl),
  CustomerManagerUiUrl: normalizeUrl(hosting.CustomerManagerUiUrl),
  EpimUiWildcardBaseUri: normalizeUrl(hosting.EpimUiWildcardBaseUri.replace('{0}', '*')),
  NavexAdminBaseUri: normalizeUrl(hosting.NavexAdminBaseUri),
  CustomerAdminBaseUri: normalizeUrl(hosting.CustomerAdminBaseUri),
  AppShellSvcBaseUri: normalizeUrl(api.AppShellSvcBaseUri),
  NavexCdnBaseUri: normalizeUrl(api.NavexCdnBaseUri),
  LanguageFilesBaseUri: normalizeUrl(api.LanguageFilesUrl),
  LaunchDarklyClientId: api.LaunchDarklyClientId,
  IsDevelopmentSite: api.SignOnFromLocalhost === 'enabled',
  InitialLogLevel: api.InitialLogLevel,
  KeyCloakUrl: normalizeUrl(hosting.KeyCloakUrl),
  IsDoormanInEnvironment: hosting.EnvironmentAuthStack !== 'devint-keycloak-only-no-doorman',
  PlatformAdminHelpUrl: normalizeUrl(hosting.PlatformAdminHelpUrl),
  SessionStatusUiJsUrl: normalizeUrl(api.SessionStatusUiJsUrl)
}

const adminHost = new BaseHost(config.NavexAdminBaseUri)

export default {
  ...config,
  adminHost
}
