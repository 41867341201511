import settings from './core/settings'

interface ISessionStatusUiOptions {
  // The div where the UI will be rendered, if not supplied or found a div will be created with a random ID and placed at the end of the HTML body content.
  divId: string
  // The language code to use for the SSUI UI, if not present it will fallback to en-US
  lang: string
  // This callback allows application to take actions right before the current window/frame is sent to the logout screen.
  logoutCallback?: () => {}
  // Any value specified here will overwrite the default values, a list of values and dev defaults can be found here: https://github.com/tnwinc/session-status-ui/blob/develop/src/config.ts
  configOverrides?: {}
}

type Navex = {
  SessionStatusUI?: {
    inject?: (options: ISessionStatusUiOptions) => void
  }
}

declare const Navex: undefined | Navex

const loadScript = (scriptTagId: string, scriptUrl: string): Promise<void> => {
  return new Promise((resolve) => {
    const scriptTag = document.createElement('script')
    scriptTag.id = scriptTagId
    scriptTag.src = scriptUrl
    scriptTag.async = true
    scriptTag.onload = () => {
      resolve()
    }
    scriptTag.onerror = () => {
      console.error(`Could not load ${scriptUrl}`)
    }
    document.head.appendChild(scriptTag)
  })
}

const injectSessionStatusUi = async (divId: string, lang: string): Promise<void> => {
  try {
    await loadScript('session-status-ui-script', settings.SessionStatusUiJsUrl)
    const sessionStatusUiOptions: ISessionStatusUiOptions = {
      divId,
      lang
    }
    if (Navex?.SessionStatusUI?.inject) {
      Navex?.SessionStatusUI?.inject(sessionStatusUiOptions)
    }
  } catch (error) {
    console.error('Error loading SessionStatusUI', error)
  }
}

export const initializeSessionStatusUi = (lang: string): HTMLDivElement => {
  const ssuiDivId = 'session_status_ui'
  injectSessionStatusUi(ssuiDivId, lang)
  const div = document.createElement('div')
  div.id = ssuiDivId

  return div
}
